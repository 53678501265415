import { sortBy, orderBy } from "lodash"
import algoliasearch from "algoliasearch/lite"

export const FILTER_ENUM = Object.freeze({
  year: "year",
  nationality: "nationality",
  category: "category",
  artist: "artist",
})

export const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

export function getGroups({ fromFacets }) {
  const result = {}
  const mapper = {
    title: FILTER_ENUM.artist,
    "artist_category.name": FILTER_ENUM.category,
    "power_100_edition.name": FILTER_ENUM.year,
    "nationality.name": FILTER_ENUM.nationality,
  }

  fromFacets.forEach(({ name, data }) => {
    const options =
      name === "power_100_edition.name"
        ? orderBy(Object.keys(data), v => v, "desc")
        : sortBy(Object.keys(data))
    result[mapper[name]] = options
  })
  return result
}

export function getFilterControls({
  currentGroups,
  initialGroups,
  currentFilters,
}) {
  // Displays only available filters
  const appliedFilters = Object.entries(currentFilters).reduce(
    (acc, [name, val]) => {
      if (val) {
        acc.push(name)
      }
      return acc
    },
    []
  )

  if (appliedFilters.length === 1) {
    const currFilter = appliedFilters[0]
    const res = {
      ...currentGroups,
      // If only one filter applied, for that one filter display all options
      [currFilter]: initialGroups[currFilter],
    }

    return res
  } else {
    return currentGroups
  }
}
