import React, { useState, useRef, useMemo, useEffect } from "react"
import { Link, navigate, graphql } from "gatsby"
import styled from "styled-components"
import { breakpoints, media } from "src/styles/breakpoints"
import { animateScroll } from "react-scroll"
import SEO from "src/components/SEO"
import { PageWrapper } from "src/layouts/BaseLayout"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import Container from "src/components/Container"
import FilterControl from "src/components/FilterControl"
import { PrimaryTitle } from "src/components/Typography"
import parseContent from "src/utils/parseContent"
import { orderBy } from "lodash"
import {
  InstantSearch,
  Configure,
  connectStateResults,
} from "react-instantsearch-dom"
import { getIndexName } from "src/utils/algolia"
import {
  FILTER_ENUM,
  searchClient,
  getGroups,
  getFilterControls,
} from "src/utils/templatePower100"
import routesConfig from "src/routes.config"
import locationQueryParams from "src/utils/locationQueryParams"
import Media from "src/components/Media"

const AlgoliaHits = connectStateResults(props => {
  const { children, isSearchStalled: isLoading, searchResults } = props
  const { hits, nbHits, facets } = searchResults || {}
  return children({ isLoading, hits, nbHits, facets })
})
export const query = graphql`
  query Power100PageQuery($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...PageFragment
    }
    wordpressAcfOptions {
      options {
        site_power_100_initial_year {
          name
        }
      }
    }
  }
`

export default ({ data, location }) => {
  const {
    wordpressPage: { title, content, featured_media },
    wordpressAcfOptions: {
      options: { site_power_100_initial_year },
    },
  } = data

  function initialFilters() {
    return {
      [FILTER_ENUM.year]: site_power_100_initial_year
        ? site_power_100_initial_year.name
        : 2012,
      [FILTER_ENUM.nationality]: "",
      [FILTER_ENUM.category]: "",
      [FILTER_ENUM.artist]: "",
    }
  }

  const [currentFilters, setCurrentFilters] = useState(initialFilters())
  const filtersChanged = useRef()
  const initialGroups = useRef()

  function encodeQueryParams(filters) {
    navigate(
      routesConfig.power100({
        [FILTER_ENUM.year]: filters[FILTER_ENUM.year],
        [FILTER_ENUM.nationality]: filters[FILTER_ENUM.nationality],
        [FILTER_ENUM.category]: filters[FILTER_ENUM.category],
        [FILTER_ENUM.artist]: filters[FILTER_ENUM.artist],
      })
    )
  }

  function setCurrentFiltersEnhanced(func) {
    filtersChanged.current = true
    let newVal

    setCurrentFilters(curr => {
      newVal = func(curr)
      return newVal
    })

    encodeQueryParams(newVal)
  }

  function handleFilterChange({ name, value }) {
    setCurrentFiltersEnhanced(curr => ({
      ...curr,
      [name]: value,
    }))
  }

  function scrollToPlace(place) {
    const el = document.getElementById(`artist-${place}`)
    if (!el) return
    const scrollTop = el.offsetTop - 130
    animateScroll.scrollTo(scrollTop, {
      duration: 900,
      smooth: "easeInOutQuart",
    })
  }

  function resetFilters() {
    navigate(routesConfig.power100())
    setCurrentFiltersEnhanced(() => initialFilters())
  }

  useEffect(() => {
    // Decode query params
    const query = locationQueryParams.parse()
    const initial = initialFilters()
    const newVal = {}

    Object.entries(initial).forEach(([key, initialValue]) => {
      newVal[key] = key in query ? query[key] : initialValue
    })

    setCurrentFilters(newVal)
  }, [location.search])

  const PageContent = ({ hits, isLoading, nbHits, facets = [] }) => {
    const renderedPosts = orderBy(
      hits,
      v => {
        const { place } =
          v.acf.artist_power_100.find(
            item => Number(item.edition.name) === Number(currentFilters.year)
          ) || {}
        return place
      },
      "asc"
    )

    if (!initialGroups.current && facets.length) {
      initialGroups.current = getGroups({ fromFacets: facets })
    }

    if (!(renderedPosts || []).length) return null

    const currentGroups = getGroups({ fromFacets: facets })
    const filterControls = getFilterControls({
      currentGroups,
      initialGroups: initialGroups.current,
      currentFilters,
    })

    return (
      <>
        <Toolbar>
          <Options>
            <FilterControl
              name={FILTER_ENUM.year}
              value={currentFilters[FILTER_ENUM.year]}
              title="Year"
              options={filterControls[FILTER_ENUM.year]}
              hideAll={true}
              onChange={handleFilterChange}
            />
            <FilterControl
              name={FILTER_ENUM.nationality}
              value={currentFilters[FILTER_ENUM.nationality]}
              title="Nationality"
              options={filterControls[FILTER_ENUM.nationality]}
              onChange={handleFilterChange}
            />
            <FilterControl
              name={FILTER_ENUM.category}
              value={currentFilters[FILTER_ENUM.category]}
              title="Category"
              options={filterControls[FILTER_ENUM.category]}
              onChange={handleFilterChange}
            />
            <FilterControl
              name={FILTER_ENUM.artist}
              value={currentFilters[FILTER_ENUM.artist]}
              title="Name"
              options={filterControls[FILTER_ENUM.artist]}
              hasSearch={true}
              searchPlaceholder="Search by name…"
              onChange={handleFilterChange}
            />
          </Options>
          <Navigation>
            Navigate to
            {[...Array(10).keys()].map(index => (
              <NavigationLink
                key={index}
                onClick={() => {
                  scrollToPlace(index * 10 + 1)
                }}
              >
                {index * 10 + 1}-{index * 10 + 10}
              </NavigationLink>
            ))}
          </Navigation>
        </Toolbar>
        <Artists>
          {renderedPosts.length > 0 ? (
            renderedPosts.map((artist, index) => {
              const {
                path,
                title,
                acf,
                featured_media,
                excerpt,
                artist_category,
              } = artist
              const currentEdition = acf.artist_power_100.find(
                power_100 =>
                  power_100.edition.name === String(currentFilters.year)
              )
              const currentPlace = currentEdition && currentEdition.place
              const prevRanking = acf.artist_power_100.find(
                power_100 =>
                  power_100.edition.name === String(currentFilters.year - 1)
              )
              const prevPlace = prevRanking && prevRanking.place

              return (
                <Artist
                  to={routesConfig.artistSingle(path, {
                    year: currentFilters[FILTER_ENUM.year],
                  })}
                  key={index + "title"}
                  id={`artist-${currentPlace}`}
                >
                  <ArtistPosition as="p">{currentPlace}</ArtistPosition>
                  <ArtistImageWrapper>
                    {featured_media && (
                      <ArtistImage
                        media={featured_media}
                        size="thumbnail"
                        type="simple"
                      />
                    )}
                  </ArtistImageWrapper>
                  <ArtistDetails>
                    <ArtistName
                      as="h2"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <ArtistDescription>
                      {artist_category
                        ? `${artist_category.name} – ${excerpt}`
                        : `${excerpt}`}
                    </ArtistDescription>
                    {prevPlace && (
                      <ArtistChange>
                        {currentPlace < prevPlace && "↑ "}
                        {currentPlace > prevPlace && "↓ "}
                        {parseInt(currentFilters.year) - 1} - {prevPlace}
                      </ArtistChange>
                    )}
                  </ArtistDetails>
                </Artist>
              )
            })
          ) : (
            <NoResults>No results.</NoResults>
          )}
        </Artists>
      </>
    )
  }

  const searchParameters = useMemo(() => {
    const facetsMap = {
      title: currentFilters[FILTER_ENUM.artist],
      "artist_category.name": currentFilters[FILTER_ENUM.category],
      "power_100_edition.name": currentFilters[FILTER_ENUM.year],
      "nationality.name": currentFilters[FILTER_ENUM.nationality],
    }
    const facetFilters = []

    Object.entries(facetsMap).forEach(([facet, value]) => {
      if (value) {
        facetFilters.push(`${facet}:${value}`)
      }
    })

    return {
      facetFilters,
      facets: Object.keys(facetsMap),
      hitsPerPage: 100,
    }
  }, [currentFilters])

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={getIndexName("Artists")}
    >
      <Configure {...searchParameters} />

      <SEO title={title} image={featured_media} />
      <Ad type="power100/before_header" />
      <Wrapper>
        <Header dark={true} />
        <Container>
          <Title>
            <button title="Reset filters" onClick={resetFilters}>
              {title}
            </button>
          </Title>
          <Subtitle>{parseContent(content)}</Subtitle>

          <AlgoliaHits>{p => <PageContent {...p} />}</AlgoliaHits>
        </Container>
      </Wrapper>
      <Ad type="power100/before_footer" />
    </InstantSearch>
  )
}

const Wrapper = styled(PageWrapper)`
  min-height: 100vh;
`

const Title = styled.h1`
  margin-top: 40px;
  margin-bottom: 21px;
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 1;

  ${media.tablet} {
    font-size: 136px;
  }
`

const Subtitle = styled.div`
  margin-bottom: 35px;
  text-align: center;
  p {
    font-size: 14px;
    line-height: 20px;
  }
`

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
  padding-bottom: 15px;
  position: relative;
  z-index: 1;
  background: #fff;

  ${media.tablet} {
    padding-top: 25px;
    position: sticky;
    top: 70px;
  }
`

const Options = styled.div`
  ${media.tablet} {
    position: relative;
    top: -4px;
  }
`

const Navigation = styled.div`
  display: none;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);

  ${media.desktop} {
    display: block;
  }
`

const NavigationLink = styled.span`
  margin-left: 8px;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`

const Artists = styled.div`
  margin-bottom: 110px;
`

const Artist = styled(Link)`
  display: flex;
  padding-top: 20px;
  padding-left: 40px;
  position: relative;

  &:nth-child(n + 2) {
    border-top: 1px solid ${p => p.theme.color.mercury};
  }

  &:not(:last-child) {
    padding-bottom: 19px;
  }

  ${media.tablet} {
    padding-top: 25px;
    padding-left: 80px;

    &:not(:last-child) {
      padding-bottom: 24px;
    }
  }
`

const ArtistPosition = styled(PrimaryTitle)`
  position: absolute;
  top: 20px;
  left: 0;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    font-size: 17px;
  }

  ${media.tablet} {
    top: 25px;
  }
`

const ArtistImageWrapper = styled.div`
  width: 60px;
  min-width: 60px;
  height: 60px;
  background: #f5f5f5;
  border-radius: 50%;

  ${media.tablet} {
    width: 140px;
    min-width: 140px;
    height: 140px;
  }
`

const ArtistImage = styled(Media)`
  width: 60px !important;
  min-width: 60px !important;
  height: 60px !important;
  border-radius: 50%;

  ${media.tablet} {
    width: 140px !important;
    min-width: 140px !important;
    height: 140px !important;
  }
`

const ArtistDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;

  ${media.tablet} {
    margin-left: 90px;
  }
`

const ArtistName = styled(PrimaryTitle)`
  margin-bottom: 11px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    font-size: 17px;
  }
`

const ArtistDescription = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const ArtistChange = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
`

const NoResults = styled.p`
  margin-top: 90px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
`

export {
  Title,
  Subtitle,
  ArtistPosition,
  ArtistImage,
  ArtistName,
  ArtistDescription,
  ArtistChange,
}
